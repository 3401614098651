<template>
  <b-form
    autocomplete="off"
    @submit.prevent="searchQuestionData"
  >
    <b-row>
      <b-col md="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-template_name"
              v-model="form.params.template_name"
              type="text"
              autocomplete="off"
              :placeholder="t('Template Name')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <v-select
            v-model="form.params.service_id"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="service_name"
            :reduce="srv => srv.id"
            :placeholder="t('Select Services')"
            :options="services"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <v-select
            v-model="form.params.status"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :reduce="srv => srv.value"
            :placeholder="t('Select Status')"
            :options="statusList"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <b-form-group>
          <v-select
            v-model="form.params.period"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :reduce="op => op.value"
            :placeholder="t('Period')"
            :options="periodOptions[$i18n.locale]"
            @input="onChangePeriod"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="form.params.start_date"
              :placeholder="t('Select Start Date')"
              class="form-control"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="form.params.end_date"
              class="form-control"
              :placeholder="t('Select End Date')"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          <!-- disable:[{from:'1970-01-01',to:form.start_date}] -->
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="text-center mt-1 mb-2"
      >
        <b-button
          variant="success mx-1"
          type="submit"
        >
          {{ t('Search') }}
        </b-button>
        <b-button
          variant="danger mx-1"
          @click="resetFilter"
        >
          {{ t('Reset') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import constants from '@/constants'
import { periodOptions, getDateByPeriod, status } from '@/utils/common'

export default {
  name: 'QuestionFilter',
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      periodOptions,
      services: [],
      statusList: status,
      isDateDisable: true,
    }
  },
  setup() {
    const { t } = useUtils()
    return {
      t,
    }
  },
  mounted() {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()
    today = `${yyyy}-${mm}-${dd}`
    // this.form.params.start_date = today
    // this.form.params.end_date = today
    this.form.params.period = '1'
    store.dispatch('userManage/getServices').then(response => {
      // eslint-disable-next-line eqeqeq
      if (response.data.code == constants.SUCCESS) { this.services = response.data.data }
    })
    this.searchQuestionData()
  },
  methods: {
    onChangePeriod(val) {
      const dates = getDateByPeriod(val)
      this.form.params.start_date = dates['0']
      this.form.params.end_date = dates['1']
      this.isDateDisable = dates['2']
    },
    searchQuestionData() {
      this.$emit('refresTemplateList', this.form)
    },
    resetFilter() {
      this.form.params.template_name = ''
      this.form.params.service_id = ''
      this.form.params.status = ''
      this.form.params.period = ''
      this.form.params.start_date = ''
      this.form.params.end_date = ''
      this.searchQuestionData()
    },
  },
}
</script>

<style>

</style>
